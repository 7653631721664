<template>
<div>
    <manual-recharge-sidebar :isSidebarActive="manualRechargeSidebar" @closeSidebar="toggleManualRechargeSidebar" :data="sidebarData" @showNotify="showNotify"/>
    <vx-card id="searchTable" ref="filterCard" title="Filtros" class="user-list-filters mb-8">
      <div class="vx-row">
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Apellido</label>
          <vs-input :dir="$vs.rtl ? 'rtl' : 'ltr'" name="apellido" v-model="lastNameFilter" v-validate="'min:2'" class="mb-4 md:mb-0 w-full" type="text"/>
          <span class="text-danger text-sm" v-show="errors.has('apellido')">{{ errors.first('apellido') }}</span>
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Nombre</label>
          <vs-input :dir="$vs.rtl ? 'rtl' : 'ltr'" name="nombre" v-model="firstNameFilter" v-validate="'min:3'" class="mb-4 md:mb-0 w-full" type="text"/>
          <span class="text-danger text-sm" v-show="errors.has('nombre')">{{ errors.first('nombre') }}</span>
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Documento</label>
          <vs-input :dir="$vs.rtl ? 'rtl' : 'ltr'" name="documento" v-model="cardNumberFilter" v-validate="'min:3'" class="mb-4 sm:mb-0 w-full"/>
          <span class="text-danger text-sm" v-show="errors.has('documento')">{{ errors.first('documento') }}</span>
        </div>
        <div
            class="vx-col md:w-1/4 sm:w-1/2 w-full mt-5 ag-grid-table-actions-right">
            <vs-button id="searchButton" class="mb-4 md:mb-0" @click="searchAccount" :disabled="!isFormValid"
              >Buscar</vs-button>
          </div>
      </div>
    </vx-card>
    <div class="vx-card p-6" >

      <div class="flex flex-wrap items-center">

        <div class="flex-grow">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <!-- <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ items.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : items.length }} of {{ items.length }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div> -->
            <vs-dropdown-menu>

              <vs-dropdown-item @click="gridApi.paginationSetPageSize(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(25)">
                <span>25</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(30)">
                <span>30</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
      </div>

      <vs-table ref="table" :max-items="itemsPerPage" :data="items">

        <template slot="thead">
          <vs-th sort-key="id">ID</vs-th>
          <vs-th sort-key="name">Nombre</vs-th>
          <vs-th sort-key="last_name">Apellido</vs-th>
          <vs-th sort-key="email">Email</vs-th>
          <vs-th sort-key="balance">Tipo Documento</vs-th>
          <vs-th sort-key="status">Nro. Documento</vs-th>
          <vs-th>Acción</vs-th>
        </template>

        <template slot-scope="{data}">
          <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

              <vs-td>
                <span>{{ tr.id }}</span>
              </vs-td>

              <vs-td>
                <p class="product-name font-medium truncate">{{ tr.name }}</p>
              </vs-td>

              <vs-td>
                <p class="product-name font-medium truncate">{{ tr.last_name }}</p>
              </vs-td>

              <vs-td>
                <p class="product-category">{{ tr.email }}</p>
              </vs-td>

              <vs-td>
                <p class="product-category">{{ tr.card_type }}</p>
              </vs-td>

              <vs-td>
                <p class="product-category"> {{ tr.card_id }}</p>
              </vs-td>

              <vs-td class="whitespace-no-wrap">
                <vx-tooltip text="Recarga Manual" class="float-left">
                  <feather-icon icon="DollarSignIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click.stop="openSidebar(tr)" />
                </vx-tooltip>
              </vs-td>

            </vs-tr>
          </tbody>
        </template>
      </vs-table>

    </div>
</div>

</template>
<script>
// Store Module
import moduleUserManagement from '@/store/user-management/moduleUserManagement.js'
import moduleReport from '@/store/report/moduleReport.js'
import manualRechargeSidebar from './ManualRechargeSidebar.vue'

export default {
    components: {
      manualRechargeSidebar
    },
    data() {
        return {
            isMounted: false,
            searchQuery: '',
            items: [],
            manualRechargeSidebar: false,
            sidebarData: {},
            itemsPerPage: 10,

            timerId: 0,
            lastNameFilter: null,
            firstNameFilter: null,
            cardNumberFilter: null,
            loading: false
        }
    },
    computed: {
      currentPage() {
        if(this.isMounted) {
          return this.$refs.table.currentx
        }
        return 0
      },
      isFormValid() {
        return !this.errors.any() && (this.lastNameFilter || this.firstNameFilter || this.cardNumberFilter)
      },
    },
    methods: {
      searchAccount() {
          clearTimeout(this.timerId)
          this.timerId = setTimeout(() => {
              this.dispatchSearch()
          }, 450)

      },
      dispatchSearch() {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.$vs.loading({type: 'point'})
            this.items = []
            this.$store.dispatch(`userManagement/accountSearch`, {
              name: this.firstNameFilter ? this.firstNameFilter : null,
              last_name: this.lastNameFilter ? this.lastNameFilter : null,
              card_id: this.cardNumberFilter ? this.cardNumberFilter : null,
              profile_type_id: 1,
              status_id: 2
            })
            .then(response => {
              if(response && response.length) {
                this.items = response
              }
            })
            .finally(() => this.$vs.loading.close() )
            .catch(err => console.log(err))
          }
        })

      },
      openSidebar(data) {
        this.sidebarData = data
        this.toggleManualRechargeSidebar()
      },
      toggleManualRechargeSidebar() {
        this.manualRechargeSidebar = !this.manualRechargeSidebar
      },
      showNotify(data) {
        this.$vs.notify({
          color: data.success ? 'success' : 'danger',
          title: data.success ? 'Éxito' : 'Error',
          text: data.msg,
          time: 8000,
          position: 'top-center',
          iconPack: 'feather',
          icon: data.success ? 'icon-check' : 'icon-alert-circle',
        })
      },
      getStatusColor(status) {
        if(status == '1') return "success"
        if(status == '0') return "danger"
        return "primary"
      },
      getStatusText(status) {
        if(status == '1') return "activo"
        if(status == '0') return "inactivo"
        return "activo"
      },
    },
    created() {
        if(!moduleUserManagement.isRegistered) {
            this.$store.registerModule('userManagement', moduleUserManagement)
            moduleUserManagement.isRegistered = true
        }
        if(!moduleReport.isRegistered) {
            this.$store.registerModule('reportModule', moduleReport)
            moduleReport.isRegistered = true
        }
    },
    mounted() {
      this.isMounted = true
    }
}
</script>
