<template>
  <vs-sidebar :disabled="!canCloseSidebar" click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
        <h4>Recarga Manual de Dinero</h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div class="p-6">

        <!-- Nombre -->
        <vs-input
          disabled="disabled"
          label="Nombre"
          v-model="dataName"
          class="mt-5 w-full"
          name="item-name"
          data-vv-as="Nombre"/>
        <span class="text-danger text-sm" v-show="errors.has('item-name')">{{ errors.first('item-name') }}</span>

        <!-- Apellido -->
        <vs-input
          disabled="disabled"
          label="Apellido"
          v-model="dataLastName"
          class="mt-5 w-full"
          name="item-last-name"
          data-vv-as="Apellido"/>
        <span class="text-danger text-sm" v-show="errors.has('item-last-name')">{{ errors.first('item-last-name') }}</span>

        <!-- Documento -->
        <vs-input
          disabled="disabled"
          label="Documento"
          v-model="dataCardId"
          class="mt-5 w-full"
          name="item-card-id"
          data-vv-as="Documento"/>
        <span class="text-danger text-sm" v-show="errors.has('item-card-id')">{{ errors.first('item-card-id') }}</span>

        <!-- Tipo de Recarga -->
        <div class="mt-5">
          <label class="vs-input--label">Tipo de Recarga</label>
          <v-select browserAutocomplete="off" autocomplete="off" v-model="dataTransactionType" :reduce="dataTransactionType => dataTransactionType.value" :options="transactionTypesOptions" :clearable="false" v-validate="'required'" name="item-recharge-type" :dir="$vs.rtl ? 'rtl' : 'ltr'" data-vv-as="Tipo de Recarga"/>
          <span class="text-danger text-sm" v-show="errors.has('item-recharge-type')">{{ errors.first('item-recharge-type') }}</span>
        </div>

        <!-- Recharge -->
        <vs-input
          icon-pack="feather"
          icon="icon-dollar-sign"
          label="Monto a Recargar"
          v-model="dataRechargeAmount"
          class="mt-5 w-full"
          v-validate="{ required: true, regex: /\d+(\.\d+)?$/, min_value: 0.01, max_value: 9999, decimal: true }"
          name="recharge-amount"
          data-vv-as="Monto a Recargar"/>
        <span class="text-danger text-sm" v-show="errors.has('recharge-amount')">{{ errors.first('recharge-amount') }}</span>

        <!-- Reference -->
        <vs-input
          label="Referencia"
          v-model="dataReference"
          class="mt-5 w-full"
          v-validate="'max:50'"
          name="reference"
          data-vv-as="Referencia"/>
        <span class="text-danger text-sm" v-show="errors.has('reference')">{{ errors.first('reference') }}</span>

        <!-- Product Image -->
        <template v-if="dataReceipt">

          <!-- Image Container -->
          <div v-if="!this.isPdf" class="img-container w-64 mx-auto flex items-center justify-center">
            <img  :src="dataReceipt" alt="img" class="responsive">
          </div>
          <div v-else class="img-upload">
            <!-- <button type="button" class="btn-x-file"><i translate="translate" class="material-icons notranslate">clear</i></button> -->
            <h4 class="text-archive"><i translate="translate" class="material-icons notranslate">description</i><span>{{this.file_name}}</span></h4>
          </div>
          <span class="text-danger text-sm" v-show="errors.has('product_image')">{{ errors.first('product_image') }}</span>


          <!-- Image upload Buttons -->
          <div class="modify-img flex justify-between mt-5">
            <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg" accept="image/*,application/pdf" name="product_image">
            <vs-button class="mr-4" type="flat" @click="$refs.updateImgInput.click()">Actualizar Comprobante</vs-button>
            <!-- <vs-button type="flat" color="#999" @click="dataReceipt = null">Eliminar imágen</vs-button> -->
          </div>
          <!-- <div v-show="image_error" class="vs-alert con-icon"><i class="vs-icon notranslate icon-scale icon-alert material-icons null">new_releases</i><span class="text-danger text-sm">{{ image_error }}</span></div> -->
          <vs-alert v-show="image_error" color="danger" style="height: initial" icon-pack="feather" icon="icon-info">
            {{ image_error }}
          </vs-alert>
        </template>

        <!-- <div class="upload-img mt-5" v-if="!dataReceipt">
          <input type="file" class="hidden" ref="uploadImgInput" @change="updateCurrImg" accept="image/*,application/pdf">
          <vs-button @click="$refs.uploadImgInput.click()">Subir Comprobante</vs-button>
          <vs-alert v-show="image_error" color="danger" class="mt-5" icon-pack="feather" icon="icon-info" style="min-height:60px">{{ image_error }}</vs-alert>
        </div> -->

      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import vSelect from 'vue-select';

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    isSidebarActive(val) {
      if(!val) return
      if(Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      } else {
        let { id, name, last_name, card_id, balance } = JSON.parse(JSON.stringify(this.data))
        this.dataId = id
        this.dataName = name
        this.dataLastName = last_name
        this.dataCardId = card_id
        this.dataBalance = balance
      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    }
  },
  data() {
    return {
      dataId: null,
      dataName: null,
      dataLastName: null,
      dataCardId: null,
      dataBalance: 0,
      dataRechargeAmount: 0,
      dataReference: null,

      dataTransactionType: null,
      dataReceipt: null,

      isPdf: false,
      file_name: "",

      product_image: {
        size: '',
        width: '',
        height: ''
      },

      image_loaded: false,
      image_error: '',

      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60,
      },

      transactionTypesOptions: [],

      canCloseSidebar: true
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        console.log('set sidebar active', val, this.canCloseSidebar)
        if(!val && this.canCloseSidebar) {
          this.$emit('closeSidebar')
          this.$validator.reset()
          this.initValues()
        }
      }
    },
    isFormValid() {
      return !this.errors.any() && (this.dataRechargeAmount && this.dataRechargeAmount >= 0.01 && this.dataRechargeAmount < 10000) && this.image_error == ''
    },
  },
  methods: {
    initValues() {
        this.dataId = null
        this.dataName = null
        this.dataLastName = null
        this.dataCardId = null
        this.dataBalance = 0
        this.dataRechargeAmount = null
        this.dataReference = null
        this.dataTransactionType = null
        this.canCloseSidebar= true
    },
    submitData() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.canCloseSidebar = false

          this.$vs.dialog({
            type: 'confirm',
            color: 'danger',
            title: `Confirmar`,
            text: `Está a punto de realizar una recarga, por favor revise los datos. ¿Está seguro que desea continuar?`,
            accept: this.submitRecharge,
            cancel: () => this.canCloseSidebar = true,
            acceptText: "Confirmar"
          })
        }
      })
    },
    submitRecharge() {
      this.$vs.loading()
      const obj = {
        id_account: this.dataId,
        amount: this.dataRechargeAmount,
        type_recharge : this.dataTransactionType,
        reference: this.dataReference
        // receipt: (this.image_loaded) ? this.dataReceipt : '',
      }

      this.$store.dispatch(`reportModule/guardianRecharge`, obj)
        .then(response => {
          if (response.status)
            this.$emit('showNotify', {success: true, msg: 'Recarga exitosa!'})
            this.$emit('closeSidebar')
            this.initValues()
            this.$vs.loading.close()
        })
        .catch(err => {
          this.$emit('showNotify', {success: false, msg: err})
          this.$vs.loading.close()
        })
    },
    updateCurrImg(input) {
      this.image_loaded = false;
      this.image_error = '';
      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader();

        if(input.target.files[0]['type'] === 'application/pdf') {
          if(input.target.files[0].size > 2000000) {
            this.image_error = "El archivo excede el tamaño máximo (2 MB)";
            return false;
          }
          reader.onload = e => {
            this.dataReceipt = e.target.result;
            this.image_loaded = true;
            this.file_name = input.target.files[0].name;
            this.isPdf = true;
          }
        } else {
          this.isPdf = false;
          this.product_image.size = input.target.files[0].size;
          reader.onload = e => {
            let img = new Image();
            img.onload = () => {
              this.product_image.width = img.width;
              this.product_image.height = img.height;
              if (this.product_image.width > 3000 ) {
                this.image_error = 'La imágen debe tener un tamaño máximo de 3000px';
              } else {
                if (this.product_image.size > 1000000){
                  this.image_error = 'La imágen excede el tamaño máximo (1 MB).';
                } else {
                  this.dataReceipt = e.target.result
                  this.image_loaded = true;
                }
              }
            }
            img.src = e.target.result;
          }
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
  },
  components: {
    VuePerfectScrollbar,
    'v-select': vSelect
  },
  created() {
    this.$store.state.AppData.transactionTypes.forEach(e => {
      this.transactionTypesOptions.push({label: e.description, value: e.id})
    })
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52000;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
    p {
      margin-bottom: 2rem;
      > span {
        font-size: 0.9rem;
      }
    }
  }
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
